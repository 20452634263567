import React from 'react';
import '../App.css';

const Rian = () => {
    return (
      <div className="Home">
          <header>Hi i'm Rian</header>
      </div>
    );
  };
  
  export default Rian;